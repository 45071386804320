@mixin media-min-max-width($minvalue, $maxvalue) {
  @if $minvalue != null and $maxvalue != null {
    @media (min-width: $minvalue)  and (max-width: $maxvalue) {
      @content;
    }
  } @else if $minvalue != null {
    @media (min-width: $minvalue) {
      @content;
    }
  } @else if $maxvalue != null {
    @media (max-width: $maxvalue) {
      @content;
    }
  }
}