@use "variables" as var;

.p-column-filter-operator,
.p-column-filter-add-rule {
  display: none;
}

.p-datatable {
  .p-datatable-wrapper {
    border-radius: 4px;
    border: 1px solid #e9e9ef;
  }
}

.p-datatable .p-datatable-thead>tr>th {
  border-width: 0;
  background: #f5f5f6;
  color: rgba(6, 18, 35, 0.5);
}

.p-datatable .p-datatable-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p-datatable {
  .p-sortable-column.p-highlight {
    background: var(--table-header-bg-active);
    color: var(--primary-color);
    &:hover {
      background: var(--table-header-bg-active);
      color: var(--primary-color);
      .p-sortable-column-icon {
        color: var(--primary-color);
      }
    }
    &:active,
    &:focus {
      box-shadow: none;
    }
    .p-sortable-column-icon {
      color: var(--primary-color);

      &:hover {
        color: var(--primary-color);
      }
    }
  }
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus,
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}

.p-tabview .p-tabview-nav-container .p-tabview-nav .p-tabview-nav-link {
  border-radius: 0;
}

.p-dialog:not(.p-confirm-dialog) .p-dialog-content {
  padding: 0 6px 6px;
}

.p-confirm-dialog .p-confirm-dialog-icon {
  color: var(--warn-color);
}

.p-dialog:not(.p-confirm-dialog) .p-dialog-footer {
  padding-top: 12px;
}

.p-dialog-title {
  font-family: var(--font-family-bold) !important;
}

.p-toast {
  z-index: 1000;
  max-width: calc(100% - 40px);
}

.p-checkbox {
  .p-checkbox-box {
    &:hover {
      border-color: var(--primary-color) !important;
    }

    &.p-highlight {
      background: var(--primary-color);
      border-color: var(--primary-color);

      &:hover {
        background: var(--primary-color) !important;
        border-color: var(--primary-color) !important;
      }
    }

    &.p-focus {
      border-color: var(--primary-color) !important;
      box-shadow: 0 0 0 0.2rem var(--shadows-color) !important;
    }
  }
}

.p-image {
  .p-image-mask {
    background-color: rgba(0, 0, 0, 0.9);

    .p-image-toolbar {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      z-index: 1;
      padding: 1rem;

      .p-image-action.p-link {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #f8f9fa;
        background-color: transparent;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        margin-right: 0.5rem;
        text-align: left;
        margin: 0;
        padding: 0;
        border: none;
        cursor: pointer;
        user-select: none;

        &:hover {
          color: #f8f9fa;
          background-color: rgba(255, 255, 255, 0.1);
        }

        i {
          font-size: 1.5rem;
        }
      }
    }
  }
}

.p-input-icon-right>.p-icon-wrapper,
.p-input-icon-right>i:last-of-type {
  right: 0.5rem;
  color: #83888f;
}

.p-slidemenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}

.p-menu {
  &.pm-align-left {
    @include var.media-min-max-width(768px, null) {
      transform: translate(-92%, 0);
    }
  }
}

.p-button {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 12px;
  padding: 8px 12px 8px 12px;

  &:enabled {

    &:focus,
    &:active,
    &:hover {
      background: var(--primary-color);
    }
  }
}

.p-button.p-button-outlined {
  background-color: transparent;
  color: var(--primary-color);
  border: 1px solid;
  &:enabled {
    &:active,
    &:hover {
      background: var(--outline-btn-bg);
      color: var(--primary-color);;
      border: 1px solid;
    }
  }
  &:enabled:focus {
    background: rgba(59, 130, 246, 0.04);
  }
}

.p-selectbutton .p-button.p-highlight {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: #ffffff;
}

.p-selectbutton .p-button.p-highlight:hover {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: #ffffff;
}

.p-card {
  border-radius: 12px;
}

.p-paginator {
  gap: 16px;
  padding: 24px 0 8px 0;
  &.p-paginator-bottom {
    border-width: 0;
  }
  .p-paginator-pages {
    display: flex;
    gap: 16px;
    .p-paginator-page {
      min-width: unset;
      height: 36px;
      width: 36px;
      &:not(.p-highlight):hover {
        min-width: unset;
        height: 36px;
        width: 36px;
      }
      &.p-highlight {
        background: var(--table-paginator-bg-active);
        border-color: var(--table-paginator-bg-active);
        color: var(--primary-color);
        min-width: unset;
        height: 36px;
        width: 36px;
      }
    }
  }
  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last {
    min-width: unset;
    height: 36px;
    width: 36px;
  }
  .p-link {
    &:focus {
      box-shadow: none;
    }
  }
}

.pi {
  font-family: 'primeicons' !important;
}

.p-overlaypanel {
  &::after,
  &::before {
    content: none !important;
  }
  .p-overlaypanel-content {
    padding: 12px 0px;
    .options-container {
      padding: 12px;
    }
  }
} 

.form-group {
  small {
    &.error {
      color: var(--error-color);
    }
  }
}

.p-datepicker table td > span.p-highlight {
  color: var(--primary-color);
  background: var(--select-bg-active);
}

.gpta-confirm-dialog {
  .p-dialog-content {
    padding: 0 6px 6px;
  }
  .p-dialog-footer {
    border-top: 0;
    padding-top: 12px;
    .p-button-rounded {
      .p-button-label {
        font-weight: 400;
      }
      .p-icon-wrapper {
        display: none;
      }
      &.p-confirm-dialog-success {
        &:enabled:active,
        &:enabled:hover {
          outline: none !important;
          box-shadow: none;
          border: 1px solid var(--primary-color);
        }
      }
      &.p-confirm-dialog-reject {
        background: transparent;
        color: var(---grey);
        border: 1px solid var(--grey);
        &:enabled:active,
        &:enabled:hover {
          color: var(--grey);
          outline: none !important;
          box-shadow: none;
          border: 1px solid var(--grey);
        }
      }
      &.admin-outline-btn {
        color: var(--primary-color);
        border: 1px solid;
        &:enabled {
          &:active,
          &:hover {
            background: var(--outline-btn-bg);
            color: var(--primary-color);;
            border: 1px solid;
          }
        }
        &:enabled:focus {
          background: rgba(59, 130, 246, 0.04);
        }
      }
    }
  }
}