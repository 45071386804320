:root {
  --primary-color: #8362DF;
  --primary-color-10: rgba(131, 98, 223, 0.1);
  --primary-color-dark: #02033c;
  --grey: #6A717B;
  --light-grey: #E9E9EF;
  --lighter-grey: #f9f9f9;
  --shadows-color: rgba(6, 18, 35, 0.3);
  --font-color: #000000;
  --link-color: #8362DF;
  --suggestions-font-color: #8362DF;
  --loading-color: #fff;
  --left-message-color: #2A2A2E;
  --right-message-color: #2A2A2E;
  --conversation-new-border-color: #E9E9EF;
  --conversation-menu-main-color: #8362DF;
  --conversation-menu-btn-border-color: rgba(54, 54, 54, 0.15);
  --conversation-menu-btn-color: #2A2A2E;
  --user-nav-btn-color: #8362DF;
  --color-grey: #bbc7cd;
  --color-grey-light: #d9e0e3;
  --error-color: #C7031E;
  --info-color: #2196f3;
  --info-color-dark: #1769aa;
  --success-color: #689f38;
  --warn-color: #ffac30;
  --body-bg: url(../../../../assets/images/background.jpg);
  --top-bar-bg: #fff;
  --conversation-bg: #F8F9FA;
  --conversation-menu-bg: #fff;
  --conversation-menu-btn-bg: #fff;
  --conversation-new-bg: #fff;
  --login-logo: url(../../../../assets/images/logo.svg);
  --left-message-bg: #fff;
  --right-message-bg: #F3F0FF;
  --right-message-logo: url(../../../../assets/images/logo.svg);
  --backdrop-bg: rgba(0, 0, 0, 0.5);
  --welcome-image-url: url(../../../../assets/images/logo.svg);
  --entity-logo-url: url(../../../../assets/images/logo.svg);
  --entity-iframe-logo-url: url(../../../../assets/images/san_assistant_header.png);
  --suggestions-bg: #FFFFFF;
  --table-header-bg-active: #F3F0FF;
  --select-bg-active: #F3F0FF;
  --settings-dropdown-btn-bg: #E9E9EF;
  --table-paginator-bg-active: #F3F0FF;
  --outline-btn-bg: #F3F0FF;
  --card-info-bg: #F5F5F6;
  --conversation-top-bar-height: 0px;
  --main-container-width: 1024px;
  --main-top-margin: 1.2rem;
  --font-family-regular: "Hind Madurai Regular";
  --font-family-light: "Hind Madurai Light";
  --font-family-bold: "Hind Madurai SemiBold";
  --font-size: 16px;
}

@font-face {
  font-family: "Inter";
  font-weight: 300;
  font-style: normal;
  src: url(../../../../assets/fonts/inter/Inter-Light.ttf) format("opentype");
}
@font-face {
  font-family: "Inter";
  font-weight: 400;
  font-style: normal;
  src: url(../../../../assets/fonts/inter/Inter-Regular.ttf) format("opentype");
}
@font-face {
  font-family: "Inter";
  font-weight: 600;
  font-style: normal;
  src: url(../../../../assets/fonts/inter/Inter-SemiBold.ttf) format("opentype");
}
@font-face {
  font-family: "Open Sans Regular";
  src: url(../../../../assets/fonts/open-sans/OpenSans-Regular.ttf) format("opentype");
}
@font-face {
  font-family: "Open Sans Light";
  src: url(../../../../assets/fonts/open-sans/OpenSans-Light.ttf) format("opentype");
}
@font-face {
  font-family: "Open Sans SemiBold";
  src: url(../../../../assets/fonts/open-sans/OpenSans-SemiBold.ttf) format("opentype");
}
@font-face {
  font-family: "Hind Madurai Light";
  src: url(../../../../assets/fonts/hind_madurai/HindMadurai-Light.ttf) format("opentype");
}
@font-face {
  font-family: "Hind Madurai Regular";
  src: url(../../../../assets/fonts/hind_madurai/HindMadurai-Regular.ttf) format("opentype");
}
@font-face {
  font-family: "Hind Madurai SemiBold";
  src: url(../../../../assets/fonts/hind_madurai/HindMadurai-SemiBold.ttf) format("opentype");
}
:root {
  --primary-color: #8362DF;
  --primary-color-10: rgba(131, 98, 223, 0.1);
  --primary-color-dark: #02033c;
  --grey: #6A717B;
  --light-grey: #E9E9EF;
  --lighter-grey: #f9f9f9;
  --shadows-color: rgba(6, 18, 35, 0.3);
  --font-color: #000000;
  --link-color: #8362DF;
  --suggestions-font-color: #8362DF;
  --loading-color: #fff;
  --left-message-color: #2A2A2E;
  --right-message-color: #2A2A2E;
  --conversation-new-border-color: #E9E9EF;
  --conversation-menu-main-color: #8362DF;
  --conversation-menu-btn-border-color: rgba(54, 54, 54, 0.15);
  --conversation-menu-btn-color: #2A2A2E;
  --user-nav-btn-color: #8362DF;
  --color-grey: #bbc7cd;
  --color-grey-light: #d9e0e3;
  --error-color: #C7031E;
  --info-color: #2196f3;
  --info-color-dark: #1769aa;
  --success-color: #689f38;
  --warn-color: #ffac30;
  --body-bg: url(../../../../assets/images/background.jpg);
  --top-bar-bg: #fff;
  --conversation-bg: #F8F9FA;
  --conversation-menu-bg: #fff;
  --conversation-menu-btn-bg: #fff;
  --conversation-new-bg: #fff;
  --login-logo: url(../../../../assets/images/logo.svg);
  --left-message-bg: #fff;
  --right-message-bg: #F3F0FF;
  --right-message-logo: url(../../../../assets/images/logo.svg);
  --backdrop-bg: rgba(0, 0, 0, 0.5);
  --welcome-image-url: url(../../../../assets/images/logo.svg);
  --entity-logo-url: url(../../../../assets/images/logo.svg);
  --entity-iframe-logo-url: url(../../../../assets/images/san_assistant_header.png);
  --suggestions-bg: #FFFFFF;
  --table-header-bg-active: #F3F0FF;
  --select-bg-active: #F3F0FF;
  --settings-dropdown-btn-bg: #E9E9EF;
  --table-paginator-bg-active: #F3F0FF;
  --outline-btn-bg: #F3F0FF;
  --card-info-bg: #F5F5F6;
  --conversation-top-bar-height: 0px;
  --main-container-width: 1024px;
  --main-top-margin: 1.2rem;
  --font-family-regular: "Hind Madurai Regular";
  --font-family-light: "Hind Madurai Light";
  --font-family-bold: "Hind Madurai SemiBold";
  --font-size: 16px;
}

@font-face {
  font-family: "Inter";
  font-weight: 300;
  font-style: normal;
  src: url(../../../../assets/fonts/inter/Inter-Light.ttf) format("opentype");
}
@font-face {
  font-family: "Inter";
  font-weight: 400;
  font-style: normal;
  src: url(../../../../assets/fonts/inter/Inter-Regular.ttf) format("opentype");
}
@font-face {
  font-family: "Inter";
  font-weight: 600;
  font-style: normal;
  src: url(../../../../assets/fonts/inter/Inter-SemiBold.ttf) format("opentype");
}
@font-face {
  font-family: "Open Sans Regular";
  src: url(../../../../assets/fonts/open-sans/OpenSans-Regular.ttf) format("opentype");
}
@font-face {
  font-family: "Open Sans Light";
  src: url(../../../../assets/fonts/open-sans/OpenSans-Light.ttf) format("opentype");
}
@font-face {
  font-family: "Open Sans SemiBold";
  src: url(../../../../assets/fonts/open-sans/OpenSans-SemiBold.ttf) format("opentype");
}
@font-face {
  font-family: "Hind Madurai Light";
  src: url(../../../../assets/fonts/hind_madurai/HindMadurai-Light.ttf) format("opentype");
}
@font-face {
  font-family: "Hind Madurai Regular";
  src: url(../../../../assets/fonts/hind_madurai/HindMadurai-Regular.ttf) format("opentype");
}
@font-face {
  font-family: "Hind Madurai SemiBold";
  src: url(../../../../assets/fonts/hind_madurai/HindMadurai-SemiBold.ttf) format("opentype");
}
.table-options {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 6px;
  align-items: center;
  justify-content: space-between;
}

.table-options-records {
  display: flex;
  margin-left: auto;
  font-size: 0.85rem;
  align-items: center;
  justify-content: flex-end;
}
.table-options-records .p-dropdown {
  width: 100px;
  margin: 0px 12px;
  min-width: unset;
}
.table-options-records .p-dropdown .p-dropdown-label {
  font-size: 0.85rem;
}

.button-cancel {
  background-color: var(--color-grey);
  border: 1px solid var(--color-grey);
}
.button-cancel:enabled:hover {
  background-color: var(--color-grey-light);
  border-color: var(--color-grey-light);
}

.link {
  text-decoration: none;
  color: var(--info-color);
  cursor: pointer;
}
.link:hover {
  color: var(--info-color-dark);
}

.rounded-btn {
  font-size: 0.86rem;
  color: var(--conversation-menu-btn-color) !important;
  background-color: var(--conversation-menu-bg) !important;
  border-color: var(--conversation-menu-btn-border-color) !important;
  padding: 0.5rem 0.75rem !important;
}
.rounded-btn svg-icon svg {
  height: 1rem;
  fill: var(--conversation-menu-btn-color);
}
.rounded-btn svg-icon + span {
  margin-left: 0.3rem;
}

@font-face {
  font-family: "Inter Regular";
  src: url(../../../assets/fonts/inter/Inter-Regular.ttf) format("opentype");
}
@font-face {
  font-family: "Inter Light";
  src: url(../../../assets/fonts/inter/Inter-Light.ttf) format("opentype");
}
@font-face {
  font-family: "Inter SemiBold";
  src: url(../../../assets/fonts/inter/Inter-SemiBold.ttf) format("opentype");
}
* {
  font-family: var(--font-family-regular) !important;
}

html {
  font-size: var(--font-size);
  color: var(--font-color);
}

body {
  margin: 0;
  overflow-x: hidden;
  font-family: var(--font-family-regular);
  background: var(--body-bg);
  background-repeat: no-repeat;
  background-size: cover;
}
body.pending-subscription-advise .top-content {
  top: 98px !important;
}
body .iframe-control {
  display: none;
}
@media (max-width: 640px) {
  body.viewMode-iframe gpta-header-nav {
    padding-bottom: 10px;
    background: linear-gradient(180deg, var(--conversation-bg) 0%, var(--conversation-bg) 70%, rgba(255, 255, 255, 0) 100%);
  }
}
body.viewMode-iframe gpta-header-nav .entity-image {
  background-image: var(--entity-iframe-logo-url);
  cursor: pointer;
}
body.viewMode-iframe gpta-header-nav .entity-image.is-mobile {
  margin: auto;
  visibility: visible;
}
body.viewMode-iframe gpta-header-nav > div > button {
  display: none;
}
body.viewMode-iframe .iframe-control {
  display: block;
}

.light {
  font-family: var(--font-family-light) !important;
}

.bold {
  font-family: var(--font-family-bold) !important;
}

.text-small {
  font-size: small !important;
}

.text-italic {
  font-style: italic !important;
}

.pointer {
  cursor: pointer !important;
}

a {
  color: var(--link-color);
}

.disabled {
  cursor: default !important;
  pointer-events: none !important;
  opacity: 0.6 !important;
}

.invisible-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: inline-block;
}

.search-table-input {
  width: 100%;
}
.search-table-input:hover {
  border-color: var(--gery) !important;
}
.search-table-input:active, .search-table-input:focus {
  border-color: var(--primary-color) !important;
  box-shadow: none !important;
}

.large-btn {
  padding: 16px 24px !important;
}

.normal-btn {
  padding: 13px 16px !important;
}

.small-btn {
  padding: 8px 16px !important;
}

.filter-select .p-dropdown:not(.p-disabled):hover {
  border-color: var(--gery) !important;
}
.filter-select .p-dropdown:not(.p-disabled):active, .filter-select .p-dropdown:not(.p-disabled):focus {
  border-color: var(--primary-color) !important;
  box-shadow: none !important;
}
.filter-select .p-dropdown:not(.p-disabled).p-focus {
  border-color: var(--primary-color) !important;
  box-shadow: none !important;
}
.filter-select .p-dropdown:not(.p-disabled) .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: var(--primary-color);
  background: var(--select-bg-active);
}
.filter-select .p-multiselect:not(.p-disabled):hover {
  border-color: var(--gery) !important;
}
.filter-select .p-multiselect:not(.p-disabled):active, .filter-select .p-multiselect:not(.p-disabled):focus {
  border-color: var(--primary-color) !important;
  box-shadow: none !important;
}
.filter-select .p-multiselect:not(.p-disabled) .p-inputtext:enabled:hover {
  border-color: var(--gery) !important;
}
.filter-select .p-multiselect:not(.p-disabled) .p-inputtext:enabled:active, .filter-select .p-multiselect:not(.p-disabled) .p-inputtext:enabled:focus {
  border-color: var(--primary-color) !important;
  box-shadow: none !important;
}
.filter-select .p-multiselect:not(.p-disabled) .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  box-shadow: none;
}
.filter-select .p-multiselect:not(.p-disabled) .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: var(--primary-color);
  background: var(--select-bg-active);
}

.form-input:hover {
  border-color: var(--gery) !important;
}
.form-input:active, .form-input:focus {
  border-color: var(--primary-color) !important;
  box-shadow: none !important;
}
.form-input .p-inputtext:enabled:hover {
  border-color: var(--gery) !important;
}
.form-input .p-inputtext:enabled:active, .form-input .p-inputtext:enabled:focus {
  border-color: var(--primary-color) !important;
  box-shadow: none !important;
}

.tab-element .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff;
  border-color: var(--primary-color) !important;
  color: var(--primary-color) !important;
}

.ellipsis-settings-btn {
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 24px;
  height: 24px;
  border-radius: 4px !important;
  display: flex;
  justify-content: space-around;
}
.ellipsis-settings-btn:hover {
  background-color: var(--settings-dropdown-btn-bg) !important;
}
.ellipsis-settings-btn:focus {
  box-shadow: none !important;
  color: #fff !important;
  background-color: var(--primary-color) !important;
}
.ellipsis-settings-btn .pi {
  font-size: 15px;
}

.blink {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.2;
  }
}
.p-paginator-bottom button.p-disabled.p-paginator-next, .p-paginator-bottom button.p-disabled.p-paginator-last {
  display: none;
}

.p-column-filter-operator,
.p-column-filter-add-rule {
  display: none;
}

.p-datatable .p-datatable-wrapper {
  border-radius: 4px;
  border: 1px solid #e9e9ef;
}

.p-datatable .p-datatable-thead > tr > th {
  border-width: 0;
  background: #f5f5f6;
  color: rgba(6, 18, 35, 0.5);
}

.p-datatable .p-datatable-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p-datatable .p-sortable-column.p-highlight {
  background: var(--table-header-bg-active);
  color: var(--primary-color);
}
.p-datatable .p-sortable-column.p-highlight:hover {
  background: var(--table-header-bg-active);
  color: var(--primary-color);
}
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
  color: var(--primary-color);
}
.p-datatable .p-sortable-column.p-highlight:active, .p-datatable .p-sortable-column.p-highlight:focus {
  box-shadow: none;
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: var(--primary-color);
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon:hover {
  color: var(--primary-color);
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus,
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}

.p-tabview .p-tabview-nav-container .p-tabview-nav .p-tabview-nav-link {
  border-radius: 0;
}

.p-dialog:not(.p-confirm-dialog) .p-dialog-content {
  padding: 0 6px 6px;
}

.p-confirm-dialog .p-confirm-dialog-icon {
  color: var(--warn-color);
}

.p-dialog:not(.p-confirm-dialog) .p-dialog-footer {
  padding-top: 12px;
}

.p-dialog-title {
  font-family: var(--font-family-bold) !important;
}

.p-toast {
  z-index: 1000;
  max-width: calc(100% - 40px);
}

.p-checkbox .p-checkbox-box:hover {
  border-color: var(--primary-color) !important;
}
.p-checkbox .p-checkbox-box.p-highlight {
  background: var(--primary-color);
  border-color: var(--primary-color);
}
.p-checkbox .p-checkbox-box.p-highlight:hover {
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.p-checkbox .p-checkbox-box.p-focus {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 0.2rem var(--shadows-color) !important;
}

.p-image .p-image-mask {
  background-color: rgba(0, 0, 0, 0.9);
}
.p-image .p-image-mask .p-image-toolbar {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  z-index: 1;
  padding: 1rem;
}
.p-image .p-image-mask .p-image-toolbar .p-image-action.p-link {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f8f9fa;
  background-color: transparent;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
  text-align: left;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  user-select: none;
}
.p-image .p-image-mask .p-image-toolbar .p-image-action.p-link:hover {
  color: #f8f9fa;
  background-color: rgba(255, 255, 255, 0.1);
}
.p-image .p-image-mask .p-image-toolbar .p-image-action.p-link i {
  font-size: 1.5rem;
}

.p-input-icon-right > .p-icon-wrapper,
.p-input-icon-right > i:last-of-type {
  right: 0.5rem;
  color: #83888f;
}

.p-slidemenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}

@media (min-width: 768px) {
  .p-menu.pm-align-left {
    transform: translate(-92%, 0);
  }
}

.p-button {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 12px;
  padding: 8px 12px 8px 12px;
}
.p-button:enabled:focus, .p-button:enabled:active, .p-button:enabled:hover {
  background: var(--primary-color);
}

.p-button.p-button-outlined {
  background-color: transparent;
  color: var(--primary-color);
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:active, .p-button.p-button-outlined:enabled:hover {
  background: var(--outline-btn-bg);
  color: var(--primary-color);
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:focus {
  background: rgba(59, 130, 246, 0.04);
}

.p-selectbutton .p-button.p-highlight {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: #ffffff;
}

.p-selectbutton .p-button.p-highlight:hover {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: #ffffff;
}

.p-card {
  border-radius: 12px;
}

.p-paginator {
  gap: 16px;
  padding: 24px 0 8px 0;
}
.p-paginator.p-paginator-bottom {
  border-width: 0;
}
.p-paginator .p-paginator-pages {
  display: flex;
  gap: 16px;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  min-width: unset;
  height: 36px;
  width: 36px;
}
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  min-width: unset;
  height: 36px;
  width: 36px;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: var(--table-paginator-bg-active);
  border-color: var(--table-paginator-bg-active);
  color: var(--primary-color);
  min-width: unset;
  height: 36px;
  width: 36px;
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  min-width: unset;
  height: 36px;
  width: 36px;
}
.p-paginator .p-link:focus {
  box-shadow: none;
}

.pi {
  font-family: "primeicons" !important;
}

.p-overlaypanel::after, .p-overlaypanel::before {
  content: none !important;
}
.p-overlaypanel .p-overlaypanel-content {
  padding: 12px 0px;
}
.p-overlaypanel .p-overlaypanel-content .options-container {
  padding: 12px;
}

.form-group small.error {
  color: var(--error-color);
}

.p-datepicker table td > span.p-highlight {
  color: var(--primary-color);
  background: var(--select-bg-active);
}

.gpta-confirm-dialog .p-dialog-content {
  padding: 0 6px 6px;
}
.gpta-confirm-dialog .p-dialog-footer {
  border-top: 0;
  padding-top: 12px;
}
.gpta-confirm-dialog .p-dialog-footer .p-button-rounded .p-button-label {
  font-weight: 400;
}
.gpta-confirm-dialog .p-dialog-footer .p-button-rounded .p-icon-wrapper {
  display: none;
}
.gpta-confirm-dialog .p-dialog-footer .p-button-rounded.p-confirm-dialog-success:enabled:active, .gpta-confirm-dialog .p-dialog-footer .p-button-rounded.p-confirm-dialog-success:enabled:hover {
  outline: none !important;
  box-shadow: none;
  border: 1px solid var(--primary-color);
}
.gpta-confirm-dialog .p-dialog-footer .p-button-rounded.p-confirm-dialog-reject {
  background: transparent;
  color: var(---grey);
  border: 1px solid var(--grey);
}
.gpta-confirm-dialog .p-dialog-footer .p-button-rounded.p-confirm-dialog-reject:enabled:active, .gpta-confirm-dialog .p-dialog-footer .p-button-rounded.p-confirm-dialog-reject:enabled:hover {
  color: var(--grey);
  outline: none !important;
  box-shadow: none;
  border: 1px solid var(--grey);
}
.gpta-confirm-dialog .p-dialog-footer .p-button-rounded.admin-outline-btn {
  color: var(--primary-color);
  border: 1px solid;
}
.gpta-confirm-dialog .p-dialog-footer .p-button-rounded.admin-outline-btn:enabled:active, .gpta-confirm-dialog .p-dialog-footer .p-button-rounded.admin-outline-btn:enabled:hover {
  background: var(--outline-btn-bg);
  color: var(--primary-color);
  border: 1px solid;
}
.gpta-confirm-dialog .p-dialog-footer .p-button-rounded.admin-outline-btn:enabled:focus {
  background: rgba(59, 130, 246, 0.04);
}