@use "variables" as var;

.table-options {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 6px;
  align-items: center;
  justify-content: space-between;
}

.table-options-records {
  display: flex;
  margin-left: auto;
  font-size: 0.85rem;
  align-items: center;
  justify-content: flex-end;

  .p-dropdown {
    width: 100px;
    margin: 0px 12px;
    min-width: unset;

    .p-dropdown-label {
      font-size: 0.85rem;
    }
  }
}

.button-cancel {
  background-color: var(--color-grey);
  border: 1px solid var(--color-grey);

  &:enabled:hover {
    background-color: var(--color-grey-light);
    border-color: var(--color-grey-light);
  }
}

.link {
  text-decoration: none;
  color: var(--info-color);
  cursor: pointer;

  &:hover {
    color: var(--info-color-dark);
  }
}

.rounded-btn {
  font-size: 0.86rem;
  color: var(--conversation-menu-btn-color) !important;
  background-color: var(--conversation-menu-bg) !important;
  border-color: var(--conversation-menu-btn-border-color) !important;
  padding: 0.5rem 0.75rem !important;
  svg-icon {
    svg {
      height: 1rem;
      fill: var(--conversation-menu-btn-color);
    }

    & + span {
      margin-left: 0.3rem;
    }
  }
}
