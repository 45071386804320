:root {
  // colors
  --primary-color: #8362DF;
  --primary-color-10: rgba(131, 98, 223, 0.1);
  --primary-color-dark: #02033c;
  --grey: #6A717B;
  --light-grey: #E9E9EF;
  --lighter-grey: #f9f9f9;
  --shadows-color: rgba(6, 18, 35, 0.3);
  --font-color: #000000;
  --link-color: #8362DF;
  --suggestions-font-color: #8362DF;
  --loading-color: #fff;
  --left-message-color: #2A2A2E;
  --right-message-color: #2A2A2E;
  --conversation-new-border-color: #E9E9EF;
  --conversation-menu-main-color: #8362DF;
  --conversation-menu-btn-border-color: rgba(54, 54, 54, 0.15);
  --conversation-menu-btn-color: #2A2A2E;
  --user-nav-btn-color: #8362DF;

  --color-grey: #bbc7cd;
  --color-grey-light: #d9e0e3;
  --error-color: #C7031E;
  --info-color: #2196f3;
  --info-color-dark: #1769aa;
  --success-color: #689f38;
  --warn-color: #ffac30;

  //bg and images
  --body-bg: url(../../../../assets/images/background.jpg);
  --top-bar-bg: #fff;
  --conversation-bg: #F8F9FA;
  --conversation-menu-bg: #fff;
  --conversation-menu-btn-bg: #fff;
  --conversation-new-bg: #fff;
  --login-logo: url(../../../../assets/images/logo.svg);
  --left-message-bg: #fff;
  --right-message-bg: #F3F0FF;
  --right-message-logo: url(../../../../assets/images/logo.svg);
  --backdrop-bg: rgba(0, 0, 0, 0.5);
  --welcome-image-url: url(../../../../assets/images/logo.svg);
  --entity-logo-url: url(../../../../assets/images/logo.svg);
  --entity-iframe-logo-url: url(../../../../assets/images/san_assistant_header.png);
  --suggestions-bg: #FFFFFF;
  --table-header-bg-active: #F3F0FF;
  --select-bg-active: #F3F0FF;
  --settings-dropdown-btn-bg: #E9E9EF;
  --table-paginator-bg-active: #F3F0FF;
  --outline-btn-bg: #F3F0FF;
  --card-info-bg: #F5F5F6;

  // sizes
  --conversation-top-bar-height: 0px;
  --main-container-width: 1024px;
  --main-top-margin: 1.2rem;

  // fonts
  --font-family-regular: "Hind Madurai Regular";
  --font-family-light: "Hind Madurai Light";
  --font-family-bold: "Hind Madurai SemiBold";
  --font-size: 16px;
}