@use "variables" as var;
@import "./components.scss";

@font-face {
  font-family: "Inter Regular";
  src: url(../../../assets/fonts/inter/Inter-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "Inter Light";
  src: url(../../../assets/fonts/inter/Inter-Light.ttf) format("opentype");
}

@font-face {
  font-family: "Inter SemiBold";
  src: url(../../../assets/fonts/inter/Inter-SemiBold.ttf) format("opentype");
}
* {
  font-family: var(--font-family-regular) !important;
}
html {
  font-size: var(--font-size);
  color: var(--font-color);
}
body {
  margin: 0;
  overflow-x: hidden;
  font-family: var(--font-family-regular);
  background: var(--body-bg);
  background-repeat: no-repeat;
  background-size: cover;

  &.pending-subscription-advise {
    .top-content {
      top: 98px !important;
    }
  }

  .iframe-control {
    display: none;
  }

  &.viewMode-iframe {
    gpta-header-nav {
      @include var.media-min-max-width( null,640px) {
        padding-bottom: 10px;
        background: linear-gradient(180deg, var(--conversation-bg) 0%, var(--conversation-bg) 70%, rgba(255, 255, 255, 0) 100%);
      }
      .entity-image {
        background-image: var(--entity-iframe-logo-url);
        cursor: pointer;
        &.is-mobile {
          margin: auto;
          visibility: visible;
        }
      }
      > div {
        > button {
          display: none;
        }
      }
    }
    .iframe-control {
      display: block;
    }
  }
}

.light {
  font-family: var(--font-family-light) !important;
}

.bold {
  font-family: var(--font-family-bold) !important;
}

.text-small {
  font-size: small !important;
}

.text-italic {
  font-style: italic !important;
}

.pointer {
  cursor: pointer !important;
}

a {
  color: var(--link-color);
}

.disabled {
  cursor: default !important;
  pointer-events: none !important;
  opacity: 0.6 !important;
}

.invisible-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: inline-block;
}

.search-table-input {
  width: 100%;
  &:hover {
    border-color: var(--gery) !important;
  }
  &:active,
  &:focus {
    border-color: var(--primary-color) !important;
    box-shadow: none !important;
  }
}

.large-btn {
  padding: 16px 24px !important;
}

.normal-btn {
  padding: 13px 16px !important;
}

.small-btn {
  padding: 8px 16px !important;
}

.filter-select {
  .p-dropdown:not(.p-disabled) {
    &:hover {
      border-color: var(--gery) !important;
    }
    &:active,
    &:focus {
      border-color: var(--primary-color) !important;
      box-shadow: none !important;
    }
    &.p-focus {
      border-color: var(--primary-color) !important;
      box-shadow: none !important;
    }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
      color: var(--primary-color);
      background: var(--select-bg-active);
    }
  }
  .p-multiselect:not(.p-disabled) {
    &:hover {
      border-color: var(--gery) !important;
    }
    &:active,
    &:focus {
      border-color: var(--primary-color) !important;
      box-shadow: none !important;
    }
    .p-inputtext:enabled {
      &:hover {
        border-color: var(--gery) !important;
      }
      &:active,
      &:focus {
        border-color: var(--primary-color) !important;
        box-shadow: none !important;
      }
    }
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
      &:focus {
        box-shadow: none;
      }
      &.p-highlight {
        color: var(--primary-color);
        background: var(--select-bg-active);
      }
    }
  }
}

.form-input {
  &:hover {
    border-color: var(--gery) !important;
  }
  &:active,
  &:focus {
    border-color: var(--primary-color) !important;
    box-shadow: none !important;
  }
  .p-inputtext:enabled {
    &:hover {
      border-color: var(--gery) !important;
    }
    &:active,
    &:focus {
      border-color: var(--primary-color) !important;
      box-shadow: none !important;
    }
  }
}

.tab-element {
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #ffffff;
    border-color: var(--primary-color) !important;
    color: var(--primary-color) !important;
  }
}

.ellipsis-settings-btn {
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 24px;
  height: 24px;
  border-radius: 4px !important;
  display: flex;
  justify-content: space-around;
  &:hover {
    background-color: var(--settings-dropdown-btn-bg) !important;
  }
  &:focus {
    box-shadow: none !important;
    color: #fff !important;
    background-color: var(--primary-color) !important;
  }
  .pi {
    font-size: 15px;
  }
}

.blink {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.2;
  }
}

.p-paginator-bottom {
  button.p-disabled.p-paginator-next, button.p-disabled.p-paginator-last {
    display: none;
  }
}